import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { appToast } from "src/utils/toast";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";
import { plus, trash } from "src/images/NewDesign";

import DynamicDropdown, { DropdownMoreBadge } from "./DynamicDropdown";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";

type CustomFieldFilterProps = {
  isMoreFilter?: boolean;
  onChange: (value: any) => void;
  value: any;
};

const FETCH_ALL_CUSTOM_FIELDS = gql`
  query FetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;

const BASE_FIELD = {
  id: "",
  value: [],
  key: "",
  type: "",
};

const CustomFieldFilter = ({ isMoreFilter, value, onChange }: CustomFieldFilterProps) => {
  const [localValue, setLocalValue] = useState<{ id: string; type: string; key: string; value: string[] }[]>(
    value || [],
  );
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  const { data } = useQuery(FETCH_ALL_CUSTOM_FIELDS, {
    fetchPolicy: "network-only",
  });

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value || []);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  const options = useMemo(() => {
    return (data?.fetchCustomFields ?? [])
      ?.slice()
      ?.filter((item: any) => {
        return item.type === "Dropdown" || item.type === "MultiDropdown" || item.type === "Boolean";
      })
      ?.map((item: any) => ({
        id: item?.id,
        options: item?.type === "Boolean" ? ["true", "false"] : item?.options ?? [],
        key: item?.key,
        type: item?.type,
      }));
  }, [data?.fetchCustomFields]);

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (newIsOpen) {
        setIsOpen(newIsOpen);
        return;
      }

      if (!localValue || localValue.length === 0) {
        setIsOpen(false);
        if (hasChangesRef.current) {
          onChange?.(localValue);
          hasChangesRef.current = false;
        }
        return;
      }

      const emptyFieldNames = localValue.filter((item: any) => item.id === "");
      if (emptyFieldNames.length > 0) {
        appToast("Invalid Custom Field Filter! Must specify a field name");
        return;
      }

      const emptyValues = localValue.filter((item: any) => !item.value || item.value.length === 0);
      if (emptyValues.length > 0) {
        appToast("Invalid Custom Field Filter! Must specify a value");
        return;
      }

      setIsOpen(false);
      if (hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue([]);
    hasChangesRef.current = true;
  }, []);

  const handleAddCustomField = useCallback(() => {
    setLocalValue((prev) => [...prev, { ...BASE_FIELD }]);
    hasChangesRef.current = true;
  }, []);

  const handleUpdateField = useCallback((index: number, updates: any) => {
    setLocalValue((prev) => prev.map((field, i) => (i === index ? { ...field, ...updates } : field)));
    hasChangesRef.current = true;
  }, []);

  const handleRemoveField = useCallback((index: number) => {
    setLocalValue((prev) => prev.filter((_, i) => i !== index));
    hasChangesRef.current = true;
  }, []);

  const moreItems = localValue.length - 1;

  return (
    <DynamicDropdown<string[]>
      label="Custom Fields"
      onClear={handleClear}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue?.map((item: any) => item.id)}
      renderHeader={
        <>
          <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
            {localValue?.[0]?.key}
          </AppText>
          {moreItems ? (
            <DropdownMoreBadge>
              <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.inverse}>
                +{moreItems}
              </AppText>
            </DropdownMoreBadge>
          ) : null}
        </>
      }
    >
      <FlexDiv gap={16} direction="column">
        {localValue?.map((item, index: number) => {
          return (
            <FlexDiv direction="column" gap={16} style={{ padding: 16, border: "1px solid #A6AABC", borderRadius: 8 }}>
              <AppText fontSize={10} fontWeight={500} color="#A6AABC" letterSpacing={1}>
                CUSTOM FIELD {index + 1}
              </AppText>

              <FlexDiv align="center" gap={8}>
                <FlexDiv direction="column" width="100%">
                  <PhoenixMultiSelect
                    isMulti={false}
                    marginBottom={false}
                    menuPosition="fixed"
                    titleText="Choose Custom Field"
                    titleTextSpacing={8}
                    menuShouldBlockScroll
                    name="CustomField"
                    options={
                      (options
                        ?.map((item: any) => {
                          return {
                            label: item.key,
                            value: item.id,
                          };
                        })
                        ?.sort((a: OptionItem, b: OptionItem) => (a?.label < b?.label ? -1 : 1))
                        ?.filter((item: any) => {
                          return !localValue?.map((item: any) => item.id).includes(item.value);
                        }) as any) ?? []
                    }
                    value={
                      item.id
                        ? {
                            label: item.key,
                            value: item.id,
                          }
                        : {
                            label: "Select a custom field",
                            value: "",
                          }
                    }
                    onChange={(e: any) => {
                      const selectedOption = options.find((opt: any) => opt.id === e.value);
                      handleUpdateField(index, {
                        id: e.value,
                        key: e.label,
                        value: [],
                        type: selectedOption?.type,
                      });
                    }}
                  />
                </FlexDiv>
                {index !== 0 && (
                  <PhoenixIcon
                    svg={trash}
                    style={{ cursor: "pointer", marginTop: 22 }}
                    color="red"
                    size={20}
                    onClick={() => handleRemoveField(index)}
                  />
                )}
              </FlexDiv>

              <PhoenixMultiSelect
                marginBottom={false}
                menuPosition="fixed"
                menuShouldBlockScroll
                name="CustomFieldValue"
                options={
                  options
                    ?.find((customField: any) => customField.id === item.id)
                    ?.options?.map((customFieldOption: any) => {
                      return { label: customFieldOption, value: customFieldOption };
                    })
                    ?.sort((a: OptionItem, b: OptionItem) => (a?.label < b?.label ? -1 : 1)) || []
                }
                value={
                  item.value?.map((v: any) => ({
                    label: v,
                    value: v,
                  })) || []
                }
                onChange={(e: any) => {
                  handleUpdateField(index, {
                    ...item,
                    value: e?.map((opt: any) => opt.value),
                  });
                }}
              />
            </FlexDiv>
          );
        })}

        <PhoenixAppButton borderColor="transparent" buttonType="ghost-small" onClick={handleAddCustomField}>
          <PhoenixIcon svg={plus} size={16} />
          Add Custom Field
        </PhoenixAppButton>
      </FlexDiv>
    </DynamicDropdown>
  );
};

export default CustomFieldFilter;
