import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MRR_LABEL } from "../apollo/query";
import { getTitle } from "../utils/format";

interface LeadFilterContextState {
  leadFilter: LeadFilterObject;
  setLeadFilter: Dispatch<SetStateAction<LeadFilterObject>>;
  leadSort: string;
  setLeadSort: Dispatch<SetStateAction<string>>;
  tempLeadFilter: LeadFilterObject;
  setTempLeadFilter: Dispatch<SetStateAction<LeadFilterObject>>;
  currentFilterCount: number;
  selected: any;
  setSelected: Dispatch<SetStateAction<any>>;
  leadSystemFilter: LeadFilterObject;
  setLeadSystemFilter: Dispatch<SetStateAction<LeadFilterObject>>;

  resetLeadSystemFilters: () => void;
  filterNum: boolean;
  resetLeadQueueFilters: () => void;

  leadSystemOperators: additionalLeadSystemOperatorsInterface;
  setLeadSystemOperators: Dispatch<SetStateAction<additionalLeadSystemOperatorsInterface>>;
  resetLeadSystemOperators: () => void;
  resetTempLeadSystemOperators: () => void;
  tempLeadSystemOperators: additionalLeadSystemOperatorsInterface;
  setTempLeadSystemOperators: Dispatch<SetStateAction<additionalLeadSystemOperatorsInterface>>;

  leadSortAscending: boolean;
  setLeadSortAscending: Dispatch<SetStateAction<boolean>>;

  userSavedHeaders: string[];
  formatedUserSavedHeaders: string[];
  loadingUserSavedHeaders: boolean;
  refetchUserSavedHeaders: () => void;
  resetTempLeadFilter: () => void;

  leadsFilterVariables: React.MutableRefObject<LeadsFilterVariables>;
  setLeadsFilterVariables: (variables: LeadsFilterVariables) => void;
  savedViewID: string | null;
  setSavedViewID: Dispatch<SetStateAction<string | null>>;
  savedView: any | undefined;
  setSavedView: Dispatch<SetStateAction<any | undefined>>;
  resetContext: () => void;
}

export type LeadFilterObject = {
  reps: string[];
  status: string[];
  industries: string[];
  channels: string[];
  custom_fields: {
    id: string;
    key: string;
    value: any;
    type: string;
  }[];
  sub_industry: string[];
  lead_sources: string[];
  lead_creation_source: string[];
  states: string[];
  timezones: string[];
  team: string[];
  sourced_by_user: string[];
  zip_codes: string[];
  history_filter: {
    metric?: string;
    operator?: string;
    amount?: number | null;
    amount_2?: number | null;
    lowerbound_date?: string;
    upperbound_date?: string;
  };
  call_result_filter: {
    call_result_type?: string;
    call_result_option?: string[];
    lowerbound_date?: string;
    upperbound_date?: string;
  };
  rep_activity_filter: {
    user_ids: string[];
    rep_lowerbound_date?: string;
    rep_upperbound_date?: string;
  };
  next_scheduled_event: {
    NextScheduledEventDays: number | undefined;
    NextScheduledEventTypes: string[];
    lowerbound_date?: string;
    upperbound_date?: string;
  };
  notes?: string;
  created_date: {
    lowerbound_date?: string;
    upperbound_date?: string;
  };
  claimed_date: {
    lowerbound_date?: string;
    upperbound_date?: string;
  };
  missed_event: {
    lowerbound_date?: string;
    upperbound_date?: string;
  };
  [key: string]: any; // Index signature to allow any other properties with string keys
};
const DEFAULT_LEAD_FILTER_STATE = {
  types: [] as string[],
  lead_sources: [] as string[],
  lead_assignment_origin: [] as string[],
  channels: [] as string[],
  industries: [] as string[],
  //cities: [] as string[],
  states: [] as string[],
  countries: [] as string[],
  timezones: [] as string[],
};

export enum LeadFilterOperator {
  DoesInclude = "DoesInclude",
  DoesNotInclude = "DoesNotInclude",
  EmptyField = "EmptyField",
}

export interface LeadsFilterVariables {
  search_text?: string;
  lead_filter?: Record<string, any>;
  lead_system_operators?: Record<string, any>;
  skip?: number;
  take?: number;
  order_by?: string;
  ascending?: boolean;
  my_leads?: boolean;
}

export interface additionalLeadSystemOperatorsInterface {
  reps_operator: LeadFilterOperator;
  lead_sources_operator: LeadFilterOperator;
  lead_creation_source_operator: LeadFilterOperator;
  status_operator: LeadFilterOperator;
  industries_operator: LeadFilterOperator;
  sub_industry_operator: LeadFilterOperator;
  states_operator: LeadFilterOperator;
  timezones_operator: LeadFilterOperator;
  channels_operator: LeadFilterOperator;
}

export const DEFAULT_LEAD_SYSTEM_OPERATORS_STATE = {
  reps_operator: LeadFilterOperator.DoesInclude,
  lead_sources_operator: LeadFilterOperator.DoesInclude,
  lead_creation_source_operator: LeadFilterOperator.DoesInclude,
  status_operator: LeadFilterOperator.DoesInclude,
  industries_operator: LeadFilterOperator.DoesInclude,
  sub_industry_operator: LeadFilterOperator.DoesInclude,
  states_operator: LeadFilterOperator.DoesInclude,
  timezones_operator: LeadFilterOperator.DoesInclude,
  channels_operator: LeadFilterOperator.DoesInclude,
};

const DEFAULT_LEAD_SYSTEM_FILTER: LeadFilterObject = {
  reps: [] as string[],
  status: [] as string[],
  industries: [] as string[],
  channels: [] as string[],
  custom_fields: [],
  sub_industry: [] as string[],
  lead_sources: [] as string[],
  lead_creation_source: [] as string[],
  states: [] as string[],
  timezones: [] as string[],
  team: [] as string[],
  sourced_by_user: [] as string[],
  zip_codes: [],
  history_filter: {
    metric: undefined, //null
    operator: undefined, //null
    amount: undefined, //null
    amount_2: undefined, //null
    lowerbound_date: undefined,
    upperbound_date: undefined,
  },
  call_result_filter: {
    call_result_type: undefined, //null
    call_result_option: [], //null
    lowerbound_date: undefined,
    upperbound_date: undefined,
  },
  rep_activity_filter: {
    user_ids: [],
    rep_lowerbound_date: "",
    rep_upperbound_date: "",
  },
  next_scheduled_event: {
    NextScheduledEventDays: undefined as number | undefined,
    NextScheduledEventTypes: [] as string[],
    lowerbound_date: undefined,
    upperbound_date: undefined,
  },
  notes: "",
  created_date: {
    lowerbound_date: undefined,
    upperbound_date: undefined,
  },
  claimed_date: {
    lowerbound_date: undefined,
    upperbound_date: undefined,
  },
  missed_event: {
    lowerbound_date: undefined,
    upperbound_date: undefined,
  },
};

const FETCH_HEADERS = gql`
  query fetchUser {
    fetchUser {
      visible_my_leads_columns_computed
    }
  }
`;

export const LeadFilterContext = createContext<LeadFilterContextState>({} as LeadFilterContextState);

export const LeadFilterProvider: FunctionComponent = ({ children }) => {
  const { data: mrrLabel } = useQuery(MRR_LABEL);
  const [savedViewID, setSavedViewID] = useState<string | null>(() => {
    const lsSavedViewID = localStorage?.getItem("savedViewID");
    return lsSavedViewID ? JSON.parse(lsSavedViewID) : null;
  });
  const [savedView, setSavedView] = useState<any | undefined>(undefined);

  const [leadFilter, setLeadFilter] = useState(() => {
    const lsLeadFilter = localStorage?.getItem("leadFilter");
    if (lsLeadFilter) {
      return { ...DEFAULT_LEAD_FILTER_STATE, ...JSON.parse(lsLeadFilter) };
    }
    return DEFAULT_LEAD_FILTER_STATE;
  });

  const [leadSystemOperators, setLeadSystemOperators] = useState(() => {
    const lsLeadSystemOperators = localStorage?.getItem("leadSystemOperators");
    if (lsLeadSystemOperators) {
      return { ...DEFAULT_LEAD_SYSTEM_OPERATORS_STATE, ...JSON.parse(lsLeadSystemOperators) };
    }
    return DEFAULT_LEAD_SYSTEM_OPERATORS_STATE;
  });

  const [leadSystemFilter, setLeadSystemFilter] = useState<LeadFilterObject>(() => {
    const lsLeadSystemFilter = localStorage?.getItem("leadSystemFilter");
    if (lsLeadSystemFilter) {
      return { ...DEFAULT_LEAD_SYSTEM_FILTER, ...JSON.parse(lsLeadSystemFilter) };
    }
    return DEFAULT_LEAD_SYSTEM_FILTER;
  });

  const [leadSort, setLeadSort] = useState(JSON.parse(localStorage.getItem("leadSort") || '""') || "");

  const leadsFilterVariables = useRef<LeadsFilterVariables>({});

  const setLeadsFilterVariables = useCallback((variables: LeadsFilterVariables) => {
    leadsFilterVariables.current = variables;
  }, []);

  const { data: userSavedHeaders, loading: loadingUserSavedHeaders, refetch: refetchUserSavedHeaders } = useQuery(
    FETCH_HEADERS,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },

      onCompleted(data) {
        const columns = data?.fetchUser?.visible_my_leads_columns_computed;
        const formatedColumns = columns?.map((column: string) => {
          return getTitle(column, mrrLabel);
        });
        // if the user has no saved sort set the default to the first column
        if (!leadSort || leadSort === "") {
          setLeadSort(formatedColumns[0]);
        }

        // if the current sort is not in the user's saved columns, set the default to the first column
        if (formatedColumns?.indexOf(leadSort) === -1) {
          setLeadSort(formatedColumns[0]);
        }
      },
    },
  );

  const [leadSortAscending, setLeadSortAscending] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("leadSortAscending");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    localStorage.setItem("savedViewID", JSON.stringify(savedViewID) || "");
  }, [savedViewID]);

  useEffect(() => {
    localStorage.setItem("leadFilter", JSON.stringify(leadFilter) || "");
  }, [leadFilter]);

  useEffect(() => {
    localStorage.setItem("leadSortAscending", JSON.stringify(leadSortAscending) || "true");
  }, [leadSortAscending]);

  const [selected, setSelected] = useState(JSON.parse(localStorage.getItem("selected_filters") || "[]") || []);

  useEffect(() => {
    localStorage.setItem("selected_filters", JSON.stringify(selected) || "[]");
  }, [selected]);

  // system view filters
  useEffect(() => {
    localStorage.setItem("leadSystemFilter", JSON.stringify(leadSystemFilter) || "");
  }, [leadSystemFilter]);

  // system view sort change when user changes the sort after login
  useEffect(() => {
    localStorage.setItem("leadSort", JSON.stringify(leadSort) || "");
  }, [leadSort]);

  // system view operators
  useEffect(() => {
    localStorage.setItem("leadSystemOperators", JSON.stringify(leadSystemOperators) || "{}");
  }, [leadSystemOperators]);

  // initial state should be same as saved lead system operators
  const [tempLeadSystemOperators, setTempLeadSystemOperators] = useState(() => {
    const lsLeadSystemOperators = localStorage?.getItem("leadSystemOperators");
    if (lsLeadSystemOperators) {
      return { ...DEFAULT_LEAD_SYSTEM_OPERATORS_STATE, ...JSON.parse(lsLeadSystemOperators) };
    }
    return DEFAULT_LEAD_SYSTEM_OPERATORS_STATE;
  });

  const [tempLeadFilter, setTempLeadFilter] = useState<LeadFilterObject>(
    // inital state should be same as saved lead filter
    () => {
      const lsLeadSystemFilter = localStorage?.getItem("leadSystemFilter");
      if (lsLeadSystemFilter) {
        return { ...DEFAULT_LEAD_SYSTEM_FILTER, ...JSON.parse(lsLeadSystemFilter) };
      }
      return DEFAULT_LEAD_SYSTEM_FILTER;
    },
  );

  const resetTempLeadFilter = () => {
    setTempLeadFilter(DEFAULT_LEAD_SYSTEM_FILTER);
    setTempLeadSystemOperators(DEFAULT_LEAD_SYSTEM_OPERATORS_STATE);
  };

  const resetTempLeadSystemOperators = () => {
    setTempLeadSystemOperators(DEFAULT_LEAD_SYSTEM_OPERATORS_STATE);
  };

  const resetSavedView = () => {
    setSavedViewID(null);
    setSavedView(null);
  };

  //memoize the current filter count
  const currentFilterCount = useMemo(() => {
    let count = 0;

    // call result

    if (tempLeadFilter?.call_result_filter?.call_result_type) {
      count++;
    }

    // custom fields

    for (const key in tempLeadFilter?.custom_fields) {
      if (!!tempLeadFilter?.custom_fields[key]?.value.length) {
        count++;
        // stop checking if any of the custom fields have a value
        break;
      }
    }

    // call history

    if (tempLeadFilter?.call_history_filter?.metric) {
      count++;
    }

    // lead history

    if (tempLeadFilter?.history_filter?.metric) {
      count++;
    }

    // industries

    if (
      tempLeadFilter?.industries?.length ||
      tempLeadSystemOperators?.industries_operator === LeadFilterOperator.EmptyField
    ) {
      count++;
    }

    // lead creation source

    if (
      tempLeadFilter?.lead_creation_source?.length ||
      tempLeadSystemOperators?.lead_creation_source_operator === LeadFilterOperator.EmptyField
    ) {
      count++;
    }

    // lead sources
    if (
      tempLeadFilter?.lead_sources?.length ||
      tempLeadSystemOperators?.lead_sources_operator === LeadFilterOperator.EmptyField
    ) {
      count++;
    }

    // next scheduled event

    if (tempLeadFilter?.next_scheduled_event?.NextScheduledEventTypes?.length) {
      count++;
    }

    // rep acitivty

    if (tempLeadFilter?.rep_activity_filter?.user_ids?.length) {
      count++;
    }

    // reps

    if (tempLeadFilter?.reps?.length || tempLeadSystemOperators?.reps_operator === LeadFilterOperator.EmptyField) {
      count++;
    }

    // states
    if (tempLeadFilter?.states?.length || tempLeadSystemOperators?.states_operator === LeadFilterOperator.EmptyField) {
      count++;
    }

    //status

    if (tempLeadFilter?.status?.length || tempLeadSystemOperators?.status_operator === LeadFilterOperator.EmptyField) {
      count++;
    }

    // sub industries

    if (
      tempLeadFilter?.sub_industry?.length ||
      tempLeadSystemOperators?.sub_industry_operator === LeadFilterOperator.EmptyField
    ) {
      count++;
    }

    // timezones

    if (
      tempLeadFilter?.timezones?.length ||
      tempLeadSystemOperators?.timezones_operator === LeadFilterOperator.EmptyField
    ) {
      count++;
    }

    // channels

    if (tempLeadFilter?.channels?.length) {
      count++;
    }

    return count;
    // the dependency array specifies the exact values that will trigger a re-count
    // this will reduce the number of times the count is recalculated for keys that have multiple values
  }, [
    tempLeadFilter?.call_result_filter?.call_result_type,
    tempLeadFilter?.custom_fields,
    tempLeadFilter?.call_history_filter?.metric,
    tempLeadFilter?.history_filter?.metric,
    tempLeadFilter?.industries,
    tempLeadFilter?.lead_creation_source,
    tempLeadFilter?.lead_sources,
    tempLeadFilter?.next_scheduled_event?.NextScheduledEventTypes,
    tempLeadFilter?.rep_activity_filter?.user_ids,
    tempLeadFilter?.reps,
    tempLeadFilter?.states,
    tempLeadFilter?.status,
    tempLeadFilter?.sub_industries,
    tempLeadFilter?.timezones,
    tempLeadFilter?.channels,
    tempLeadSystemOperators,
  ]);

  const resetLeadSystemOperators = () => {
    setLeadSystemOperators(DEFAULT_LEAD_SYSTEM_OPERATORS_STATE);
    setTempLeadSystemOperators(DEFAULT_LEAD_SYSTEM_OPERATORS_STATE);
  };

  const resetLeadSystemFilters = () => {
    const newLeadSystemFilter: LeadFilterObject = {
      ...DEFAULT_LEAD_SYSTEM_FILTER,
    };
    setLeadSystemFilter(newLeadSystemFilter);
    setTempLeadFilter(newLeadSystemFilter);
  };

  const resetLeadQueueFilters = () => {
    setSelected([]);
    setLeadFilter(DEFAULT_LEAD_FILTER_STATE);
  };

  // BE expects headers to be saved and updated for the user as "BussinesName, FirstName, etc..."
  // However for all other uses such as the all leads table sort it expectes "Bussines Name, First Name" for the sort value
  // I tried to help with this by having all in app headsers from the fetch onward be able to look at formated headers and use those when choosing sort etc...

  // long term we need a FE/BE solution because this is anti pattern
  const rawHeaders = userSavedHeaders?.fetchUser?.visible_my_leads_columns_computed;

  const formatedHeaders = rawHeaders?.map((item: string) => {
    return getTitle(item, mrrLabel);
  });

  /**
   * The number of filters applied
   */
  const filterNum =
    leadFilter.types.length +
    leadFilter.lead_sources.length +
    // leadFilter.channels.length +
    leadFilter.industries.length +
    leadFilter.states.length +
    leadFilter.countries.length +
    leadFilter.timezones.length;

  const resetContext = () => {
    resetLeadQueueFilters();
    resetLeadSystemOperators();
    resetLeadSystemFilters();
    resetTempLeadFilter();
    resetTempLeadSystemOperators();
    resetSavedView();
    setLeadSort("");
    setLeadsFilterVariables({});
  };

  const memoizedValue = useMemo(
    () => ({
      leadFilter,
      leadSort,
      setLeadSort,
      setLeadFilter,
      tempLeadFilter,
      setTempLeadFilter,
      currentFilterCount,
      selected,
      setSelected,
      leadSystemFilter,
      setLeadSystemFilter,

      resetLeadSystemFilters,
      filterNum,
      resetLeadQueueFilters,
      tempLeadSystemOperators,
      setTempLeadSystemOperators,
      leadSystemOperators,
      setLeadSystemOperators,
      resetLeadSystemOperators,
      leadSortAscending,
      setLeadSortAscending,
      loadingUserSavedHeaders,
      userSavedHeaders: rawHeaders,
      formatedUserSavedHeaders: formatedHeaders,
      refetchUserSavedHeaders,
      resetTempLeadFilter,
      resetTempLeadSystemOperators,
      leadsFilterVariables,
      setLeadsFilterVariables,
      savedView,
      savedViewID,
      setSavedView,
      setSavedViewID,
      resetContext,
    }),
    [
      leadFilter,
      leadSort,
      setLeadSort,
      leadSort,
      setLeadSort,
      setLeadFilter,
      tempLeadFilter,
      setTempLeadFilter,
      currentFilterCount,
      selected,
      setSelected,
      leadSystemFilter,
      setLeadSystemFilter,

      resetLeadSystemFilters,
      filterNum,
      resetLeadQueueFilters,

      tempLeadSystemOperators,
      setTempLeadSystemOperators,
      leadSystemOperators,
      setLeadSystemOperators,
      resetLeadSystemOperators,
      leadSortAscending,
      setLeadSortAscending,
      userSavedHeaders,
      formatedHeaders,
      loadingUserSavedHeaders,
      refetchUserSavedHeaders,
      resetTempLeadFilter,
      resetTempLeadSystemOperators,
      savedView,
      savedViewID,
      setSavedView,
      setSavedViewID,
    ],
  );

  return <LeadFilterContext.Provider value={memoizedValue}>{children}</LeadFilterContext.Provider>;
};
