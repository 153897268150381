import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { LeadFilterOperator } from "src/context";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI";
import { PhoenixCheckbox, PhoenixIcon, PhoenixInput } from "src/Components/UI/Phoenix";
import { search } from "src/images/NewDesign";
import { FILTER_OPERATIONS } from "./shared";
import DynamicDropdown, {
  DropdownComparisonBadge,
  DropdownOptionItem,
  DropdownOptionList,
  DropdownMoreBadge,
} from "./DynamicDropdown";
import FilterSelect from "./FilterSelect";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";

type MultiSelectFilterProps = {
  canSearch?: boolean;
  closeOnChange?: boolean;
  icon?: any;
  isMoreFilter?: boolean;
  label: string;
  name: string;
  onChange: (v: string[]) => void;
  onChangeOperator?: (v: LeadFilterOperator) => void;
  operator?: LeadFilterOperator;
  options: (OptionItem & Record<string, any>)[];
  renderHeader?: React.ReactNode | null;
  renderOption?: (option: OptionItem & Record<string, any>) => React.ReactNode;
  showMoreBadge?: boolean;
  stateless?: boolean;
  value: string[];
};

const MultiSelectFilter = ({
  canSearch,
  closeOnChange,
  icon,
  isMoreFilter,
  label,
  name,
  onChange,
  onChangeOperator,
  operator,
  options = [],
  renderHeader: customRenderHeader,
  renderOption,
  showMoreBadge = true,
  stateless,
  value,
}: MultiSelectFilterProps) => {
  const hasChangesRef = useRef(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [localValue, setLocalValue] = useState<string[]>(value);
  const [localOperator, setLocalOperator] = useState<LeadFilterOperator | undefined>(operator);
  const [isOpen, setIsOpen] = useState(false);

  const finalValue = stateless ? value : localValue;
  const finalOperator = stateless ? operator : localOperator;
  const finalOnChange = stateless ? onChange : setLocalValue;
  const finalOnChangeOperator = stateless ? onChangeOperator : setLocalOperator;

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  useEffect(() => {
    if (!isOpen && !stateless) {
      setLocalValue(value);
      setLocalOperator(operator);
      hasChangesRef.current = false;
    }
  }, [value, operator, isOpen, stateless]);

  useEffect(() => {
    if (!stateless) {
      hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value) || localOperator !== operator;
    }
  }, [localValue, value, localOperator, operator, stateless]);

  const selectedOperator = useMemo(() => FILTER_OPERATIONS.find((item) => item.value === finalOperator), [
    finalOperator,
  ]);

  const filteredOptions = useMemo(
    () =>
      searchQuery
        ? options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()))
        : options,
    [options, searchQuery],
  );

  const selectedOptions = useMemo(() => options.filter((option) => finalValue.includes(option.value.toString())), [
    options,
    finalValue,
  ]);

  const selectedLabelsString = useMemo(
    () => finalValue.map((item) => options.find((option) => option.value === item)?.label).join(", "),
    [options, finalValue],
  );

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen);
      if (!newIsOpen && hasChangesRef.current && !stateless) {
        onChange(localValue);
        onChangeOperator?.(localOperator as LeadFilterOperator);
        hasChangesRef.current = false;
      }
    },
    [localValue, localOperator, onChange, onChangeOperator, stateless],
  );

  const handleSelect = useCallback(
    (option: any) => {
      const isNewValue = !finalValue.includes(option.value);
      const newValue = isNewValue ? [...finalValue, option.value] : finalValue.filter((item) => item !== option.value);

      finalOnChange(newValue);
      if (!stateless) {
        hasChangesRef.current = true;
      }

      if (closeOnChange && isNewValue) {
        setIsOpen(false);
      }
    },
    [finalValue, finalOnChange, stateless, closeOnChange],
  );

  const handleClear = useCallback(() => {
    finalOnChange([]);
    if (!stateless) {
      hasChangesRef.current = true;
    }
  }, [finalOnChange, stateless]);

  const handleOperatorChange = useCallback(
    (v: OptionItem) => {
      finalOnChangeOperator?.(v.value as LeadFilterOperator);
      if (!stateless) {
        hasChangesRef.current = true;
      }
    },
    [finalOnChangeOperator, stateless],
  );

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const moreItems = finalValue.length - 1;

  const headerContent = useMemo(
    () =>
      customRenderHeader === null ? null : (
        <>
          {onChangeOperator ? <DropdownComparisonBadge value={selectedOperator?.label ?? ""} /> : null}
          {showMoreBadge ? (
            <>
              <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
                {selectedOptions?.[0]?.label}
              </AppText>
              {moreItems ? (
                <DropdownMoreBadge>
                  <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.inverse}>
                    +{moreItems}
                  </AppText>
                </DropdownMoreBadge>
              ) : null}
            </>
          ) : (
            <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
              {selectedLabelsString}
            </AppText>
          )}
        </>
      ),
    [
      customRenderHeader,
      moreItems,
      onChangeOperator,
      selectedLabelsString,
      selectedOperator?.label,
      selectedOptions,
      showMoreBadge,
    ],
  );

  const renderOptionItem = useCallback(
    (option: any) => (
      <DropdownOptionItem key={option.value} onClick={() => handleSelect(option)}>
        <PhoenixCheckbox checked={finalValue.includes(option.value)} style={{ marginRight: 0 }} />
        {renderOption?.(option) ?? option.label}
      </DropdownOptionItem>
    ),
    [handleSelect, finalValue, renderOption],
  );

  return (
    <DynamicDropdown<string[]>
      icon={icon}
      label={label}
      onClear={handleClear}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      renderHeader={customRenderHeader ?? headerContent}
      value={finalValue}
    >
      {onChangeOperator ? (
        <FilterSelect
          name={`${name}_operator`}
          onChange={handleOperatorChange}
          options={FILTER_OPERATIONS}
          value={finalOperator}
        />
      ) : null}

      {canSearch ? (
        <PhoenixInput
          placeholder="Search"
          insideLeftElementOverride={
            <PhoenixIcon
              svg={search}
              size={16}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
            />
          }
          onChange={handleSearchChange}
          value={searchQuery}
          displayNoContextText
        />
      ) : null}

      <DropdownOptionList>{filteredOptions.map(renderOptionItem)}</DropdownOptionList>
    </DynamicDropdown>
  );
};

export default React.memo(MultiSelectFilter);
