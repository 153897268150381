import React, { useCallback, useContext, useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { FlexDiv } from "src/Components/UI";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { usePub } from "src/hooks/usePubSub";
import { LeadFilterContext, LeadFilterObject } from "src/context";
import useLeadsFilterOptions from "src/hooks/useLeadsFilterOptions";
import { plus, sync } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import { AddOrEditLeadFilterSavedViewModal } from "src/Components/modal/AddOrEditLeadFilterSavedViewModal";
import { CloneLeadFilterSavedViewModal } from "src/Components/modal/CloneLeadFilterSavedViewModal";
import { DeleteLeadFilterSavedViewModal } from "src/Components/modal/DeleteLeadFilterSavedViewModal";
import { ProfileImageWithText } from "../SettingSegments/ProfileImageWithText";
import CallResultFilter from "./CallResultFilter";
import CustomFieldFilter from "./CustomFieldFilter";
import DateFilter from "./DateFilter";
import LeadHistoryFilter from "./LeadHistoryFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import NextScheduledEventFilter from "./NextScheduledEventFilter";
import NotesFilter from "./NotesFilter";
import RepActivityFilter from "./RepActivityFilter";
import SavedViewDetails from "./SavedViewDetails";
import SavedViewDropdown from "./SavedViewDropdown";
import { formatSavedViewFiltersToLeadFilterObject, MORE_FILTERS_OPTIONS, MoreFilterOption } from "./shared";
import SavedViewAction from "./SavedViewAction";
import useFetchLeadsSavedViews from "src/hooks/useFetchLeadsSavedViews";
import ZipCodeFilter from "./ZipCodeFilter";
import { hasValue } from "src/utils/misc";

const getMoreSelectedFilters = (leadSystemFilter: LeadFilterObject) => {
  return MORE_FILTERS_OPTIONS.filter((option) => {
    const value = leadSystemFilter[option.value];
    return value && hasValue(value);
  }).map((option) => option.value);
};

const LeadsFilterV2 = React.memo(({ myLeads }: { myLeads?: boolean }) => {
  const publish = usePub();
  const hasLoadedSavedViews = useRef(false);
  const {
    leadSystemFilter,
    setLeadSystemFilter,
    setLeadSystemOperators,
    leadSystemOperators,
    resetLeadSystemFilters,
    resetLeadSystemOperators,
    savedViewID,
    setSavedViewID,
    setSavedView,
  } = useContext(LeadFilterContext);

  const { mapOptionsToKey, getSubIndustryOptions } = useLeadsFilterOptions();

  const [moreFilters, setMoreFilters] = useState<string[]>(() => getMoreSelectedFilters(leadSystemFilter));

  const { data: savedViews, loading: loadingSavedViews } = useFetchLeadsSavedViews({
    onCompleted: (data) => {
      if (data.length && !hasLoadedSavedViews.current) {
        const defaultView = data.find((view: any) => view.is_default);
        setSavedViewID(defaultView?.id);
      }
      hasLoadedSavedViews.current = true;
    },
  });

  useEffect(() => {
    if (savedViewID && savedViews.length) {
      const view = savedViews?.find((view: any) => view.id === savedViewID);
      setSavedView(view);
      if (view) {
        publish("SAVED_VIEW_UPDATED", view);
        const newleadFilter = formatSavedViewFiltersToLeadFilterObject(view.lead_filter);
        setLeadSystemFilter(newleadFilter);
        setLeadSystemOperators(view.lead_system_operators);
        setMoreFilters(getMoreSelectedFilters(newleadFilter));
      }
    }
  }, [savedViews, savedViewID]);

  const handleReset = useCallback(() => {
    resetLeadSystemFilters();
    resetLeadSystemOperators();
    setMoreFilters([]);
  }, [resetLeadSystemFilters, resetLeadSystemOperators]);

  const renderFilterItem = useCallback(
    (option: MoreFilterOption) => {
      switch (option.type) {
        case "rep_activity_filter":
          return (
            <RepActivityFilter
              isMoreFilter
              options={mapOptionsToKey("reps")}
              value={leadSystemFilter.rep_activity_filter}
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  rep_activity_filter: { ...leadSystemFilter.rep_activity_filter, ...v },
                })
              }
            />
          );

        case "next_scheduled_event":
          return (
            <NextScheduledEventFilter
              isMoreFilter
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  next_scheduled_event: { ...leadSystemFilter.next_scheduled_event, ...v },
                })
              }
              options={mapOptionsToKey("next_scheduled_event")}
              value={leadSystemFilter.next_scheduled_event}
            />
          );

        case "custom_fields":
          return (
            <CustomFieldFilter
              isMoreFilter
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  custom_fields: [...v],
                })
              }
              value={leadSystemFilter.custom_fields}
            />
          );

        case "multi_select":
          return (
            <MultiSelectFilter
              isMoreFilter
              label={option.label}
              name={option.value}
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, [option.value]: v })}
              options={
                option.value === "sub_industry"
                  ? getSubIndustryOptions(leadSystemFilter.industries)
                  : mapOptionsToKey(option.value)
              }
              showMoreBadge={option.value !== "channels"}
              value={leadSystemFilter[option.value]}
              {...(option.hideOperator
                ? {}
                : {
                    onChangeOperator: (v) =>
                      setLeadSystemOperators({ ...leadSystemOperators, [`${option.value}_operator`]: v }),
                    operator: leadSystemOperators[
                      `${option.value}_operator` as keyof typeof leadSystemOperators
                    ] as any,
                  })}
            />
          );

        case "date":
          return (
            <DateFilter
              isMoreFilter
              label={option.label}
              name={option.value}
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  [option.value]: { ...leadSystemFilter[option.value], ...v },
                })
              }
              value={leadSystemFilter[option.value]}
            />
          );

        case "notes":
          return (
            <NotesFilter
              isMoreFilter
              value={leadSystemFilter.notes}
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, notes: v })}
            />
          );

        case "zip_codes":
          return (
            <ZipCodeFilter
              isMoreFilter
              value={leadSystemFilter.zip_codes}
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, zip_codes: v })}
            />
          );

        default:
          return null;
      }
    },
    [leadSystemFilter, setLeadSystemFilter, mapOptionsToKey, getSubIndustryOptions],
  );

  const renderRepOption = useCallback(
    (option: OptionItem & Record<string, any>) => (
      <>
        <ProfileImageWithText
          first_name={option.first_name}
          height={24}
          last_name={option.last_name}
          profile_image={option.profile_image}
          width={24}
          textSize={8}
          hideTooltip
        />
        {option.label}
      </>
    ),
    [],
  );

  const moreFilterItems = useMemo(
    () => MORE_FILTERS_OPTIONS.filter((option) => moreFilters.includes(option.value.toString())).map(renderFilterItem),
    [moreFilters, renderFilterItem],
  );

  const handleMultiSelectChange = useCallback(
    (field: string) => (v: any) => setLeadSystemFilter({ ...leadSystemFilter, [field]: v }),
    [leadSystemFilter, setLeadSystemFilter],
  );

  const handleOperatorChange = useCallback(
    (field: string) => (v: any) => setLeadSystemOperators({ ...leadSystemOperators, [`${field}_operator`]: v }),
    [leadSystemOperators, setLeadSystemOperators],
  );

  return (
    <>
      <Container>
        {!loadingSavedViews || hasLoadedSavedViews.current ? <SavedViewDetails /> : null}
        <Wrapper>
          <FlexDiv align="center" gap={8} wrap="wrap">
            <MultiSelectFilter
              label="Rep"
              name="reps"
              onChange={handleMultiSelectChange("reps")}
              onChangeOperator={handleOperatorChange("reps")}
              operator={leadSystemOperators.reps_operator}
              options={mapOptionsToKey("reps")}
              value={leadSystemFilter.reps}
              renderOption={renderRepOption}
            />
            <MultiSelectFilter
              label="Industry"
              name="industries"
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, industries: v })}
              onChangeOperator={(v) => setLeadSystemOperators({ ...leadSystemOperators, industries_operator: v })}
              operator={leadSystemOperators.industries_operator}
              options={mapOptionsToKey("industries")}
              value={leadSystemFilter.industries}
            />
            <LeadHistoryFilter
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  history_filter: { ...leadSystemFilter.history_filter, ...v },
                })
              }
              value={leadSystemFilter.history_filter}
            />
            <CallResultFilter
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  call_result_filter: { ...leadSystemFilter.call_result_filter, ...v },
                })
              }
              value={leadSystemFilter.call_result_filter}
            />
            <MultiSelectFilter
              label="Status"
              name="status"
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, status: v })}
              onChangeOperator={(v) => setLeadSystemOperators({ ...leadSystemOperators, status_operator: v })}
              operator={leadSystemOperators.status_operator}
              options={mapOptionsToKey("status")}
              value={leadSystemFilter.status}
            />
            <DateFilter
              label="Created Date"
              name="created_date"
              onChange={(v) =>
                setLeadSystemFilter({ ...leadSystemFilter, created_date: { ...leadSystemFilter.created_date, ...v } })
              }
              value={leadSystemFilter.created_date}
            />

            {moreFilterItems}

            <MultiSelectFilter
              canSearch
              icon={plus}
              closeOnChange
              label="More"
              name="more_filters"
              onChange={setMoreFilters}
              options={MORE_FILTERS_OPTIONS}
              renderHeader={null}
              stateless
              value={moreFilters}
            />

            <PhoenixAppButton buttonType="ghost-small" onClick={handleReset}>
              <PhoenixIcon svg={sync} size={12} />
              Reset Filter
            </PhoenixAppButton>
          </FlexDiv>

          <FlexDiv align="center" gap={16}>
            <SavedViewAction />
            <SavedViewDropdown savedViews={savedViews} />
          </FlexDiv>
        </Wrapper>
      </Container>

      <AddOrEditLeadFilterSavedViewModal />
      <CloneLeadFilterSavedViewModal />
      <DeleteLeadFilterSavedViewModal />
    </>
  );
});

LeadsFilterV2.displayName = "LeadsFilterV2";

export default LeadsFilterV2;

const Container = styled(FlexDiv)`
  flex-direction: column;
  gap: 16px;
  padding: 10px 0;
  width: 100%;
`;

const Wrapper = styled(FlexDiv)`
  align-items: flex-start;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  z-index: 99;
`;
