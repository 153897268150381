import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { appToast } from "src/utils/toast";
import { theme } from "src/utils/theme";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";

import DynamicDropdown from "./DynamicDropdown";
import RadioSelect from "./RadioSelect";
import DateRangePicker from "./DateRangePicker";

const GET_DISPOSITION_TYPE_OPTIONS = gql`
  query getDispositionTypeOptions {
    getDispositionTypeOptions
  }
`;

const GET_CALL_RESULT_OPTIONS = gql`
  query getCallResultOptions {
    getCallResultOptions
  }
`;

const CallResultFilter = ({ value, onChange }: { value?: any; onChange?: (value: any) => void }) => {
  const [localValue, setLocalValue] = useState<{
    call_result_type: string | undefined;
    call_result_option: string[];
    lowerbound_date: string | undefined;
    upperbound_date: string | undefined;
  }>(value);
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  const { data: dataDispositionTypeOptions } = useQuery(GET_DISPOSITION_TYPE_OPTIONS, {
    fetchPolicy: "network-only",
  });

  const { data: dataCallResultOptions } = useQuery(GET_CALL_RESULT_OPTIONS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (newIsOpen) {
        setIsOpen(newIsOpen);
        return;
      }

      const hasCallResultOption =
        Array.isArray(localValue?.call_result_option) && localValue.call_result_option.length > 0;
      const hasCallResultType = !!localValue?.call_result_type;

      if (hasCallResultOption || hasCallResultType) {
        if (!hasCallResultOption) {
          appToast("Invalid Call Result Filter! Must specify an option");
          return;
        }
        if (!hasCallResultType) {
          appToast("Invalid Call Result Filter! Must specify a type");
          return;
        }
      }

      setIsOpen(false);
      if (hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue({
      call_result_type: undefined,
      call_result_option: [],
      lowerbound_date: undefined,
      upperbound_date: undefined,
    });
    hasChangesRef.current = true;
  }, []);

  const dispositionTypeOptions: OptionItem[] = dataDispositionTypeOptions?.getDispositionTypeOptions ?? [];
  const callResultOptions: OptionItem[] = dataCallResultOptions?.getCallResultOptions ?? [];

  const selectedDispositionType = dispositionTypeOptions.find((item) => item.value === localValue?.call_result_type);
  const selectedCallResultOption = useMemo(() => {
    if (!Array.isArray(localValue?.call_result_option)) return [];
    return callResultOptions.filter((item) => localValue.call_result_option.includes(item.value.toString()));
  }, [callResultOptions, localValue?.call_result_option]);

  const label = useMemo(() => {
    return `${selectedDispositionType?.label} ${
      selectedCallResultOption?.length > 0
        ? `that is ${selectedCallResultOption.map((item) => item.label).join(", ")}`
        : ""
    }${
      localValue?.lowerbound_date && localValue?.upperbound_date
        ? `, ${moment(localValue.lowerbound_date).format("MM/DD/YYYY")} - ${moment(localValue.upperbound_date).format(
            "MM/DD/YYYY",
          )}`
        : ""
    }`.trim();
  }, [selectedDispositionType, selectedCallResultOption, localValue]);

  return (
    <DynamicDropdown<string>
      label="Call Result"
      onClear={handleClear}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue?.call_result_type ?? ""}
      renderHeader={
        <AppText
          fontSize={12}
          color={theme.PRIMARY500}
          style={{ maxWidth: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {label}
        </AppText>
      }
    >
      <FlexDiv direction="column" gap={16}>
        <RadioSelect
          direction={dispositionTypeOptions.length > 2 ? "column" : "row"}
          gap={dispositionTypeOptions.length > 2 ? 16 : 24}
          options={dispositionTypeOptions}
          onChange={(v) =>
            setLocalValue((prev: any) => ({
              ...prev,
              call_result_type: v.value,
              call_result_option: [],
            }))
          }
          value={localValue?.call_result_type}
        />
        <PhoenixMultiSelect
          isMulti={localValue?.call_result_type?.toLowerCase() === "anytime"}
          titleText="Call Result Type"
          titleTextSpacing={8}
          marginBottom={false}
          menuPosition="fixed"
          menuShouldBlockScroll
          name="call_result_option"
          options={callResultOptions}
          placeholder="Select call result type"
          value={selectedCallResultOption}
          onChange={(items: OptionItem | OptionItem[]) => {
            if (Array.isArray(items)) {
              setLocalValue((prev: any) => ({
                ...prev,
                call_result_option: items.map((item) => item.value),
              }));
            } else {
              setLocalValue((prev: any) => ({
                ...prev,
                call_result_option: [items.value],
              }));
            }
          }}
        />
        <DateRangePicker
          endDateId="call_result_end_date"
          onChange={(newValue) => setLocalValue((prev: any) => ({ ...prev, ...newValue }))}
          startDateId="call_result_start_date"
          value={localValue}
        />
      </FlexDiv>
    </DynamicDropdown>
  );
};

export default CallResultFilter;
