import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import moment from "moment";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI";
import DynamicDropdown from "./DynamicDropdown";
import DateRangePicker from "./DateRangePicker";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";

type DateFilterProps = {
  isMoreFilter?: boolean;
  label: string;
  name: string;
  onChange?: (value: any) => void;
  value?: any;
};

const DateFilter = ({ isMoreFilter, label, name, value, onChange }: DateFilterProps) => {
  const [localValue, setLocalValue] = useState<{
    lowerbound_date?: string | null;
    upperbound_date?: string | null;
  }>(value);
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  const valueText = useMemo(() => {
    return `${
      localValue?.lowerbound_date
        ? `${moment(localValue.lowerbound_date).format("MM/DD/YYYY")}${
            localValue.upperbound_date ? ` - ${moment(localValue.upperbound_date).format("MM/DD/YYYY")}` : ""
          }`
        : ""
    }`.trim();
  }, [localValue?.lowerbound_date, localValue?.upperbound_date]);

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen);
      if (!newIsOpen && hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue({
      lowerbound_date: undefined,
      upperbound_date: undefined,
    });
    hasChangesRef.current = true;
  }, []);

  const handleDateChange = useCallback(
    (newValue: { lowerbound_date?: string | null; upperbound_date?: string | null }) => {
      setLocalValue((prev) => ({
        ...prev,
        ...newValue,
      }));
      hasChangesRef.current = true;
    },
    [],
  );

  return (
    <DynamicDropdown<string>
      label={label}
      onClear={handleClear}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue?.lowerbound_date ?? ""}
      renderHeader={
        <AppText
          fontSize={12}
          color={theme.PRIMARY500}
          style={{ maxWidth: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {valueText}
        </AppText>
      }
    >
      <DateRangePicker
        endDateId={`${name}_end_date`}
        onChange={handleDateChange}
        startDateId={`${name}_start_date`}
        value={localValue}
      />
    </DynamicDropdown>
  );
};

export default DateFilter;
